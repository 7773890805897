import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import SearchChatContactContext from "../Context/SearchChatContactContext";
import Menu from "./Menu";
import Card from "./Card";
import toast, { Toaster } from "react-hot-toast";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/solid";
import { router } from "@inertiajs/react";
import { useAtom } from "jotai";
import {
    activeChatAtom,
    chatMessagesAtom,
    isChatVisibleAtom,
    nameAtom,
    sessionIdAtom,
    toAtom,
} from "../Jotai/ChatSessionAtoms";

const ChatWidget = ({ className, fetchChatSession, name }) => {
    const setActiveChat = useAtom(activeChatAtom)[1];

    const setChatMessages = useAtom(chatMessagesAtom)[1];
    const setIsChatVisible = useAtom(isChatVisibleAtom)[1];
    const setTo = useAtom(toAtom)[1];
    const setName = useAtom(nameAtom)[1];
    const setSessionId = useAtom(sessionIdAtom)[1];
    const [latestChat, setLatestChat] = useState(null);

    const [chats, setChats] = useState([
        {
            session_id: "new",
        },
    ]);

    const setShowSearchModal = useContext(SearchChatContactContext);

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const chatResponse = await axios.get("/chats");

                //                console.log({ chatResponse });

                const chatSessions = chatResponse.data
                    .filter((chat) => !!chat?.from && !chat?.to)
                    .reduce((acc, chat) => {
                        if (!acc[chat.session_id]) {
                            acc[chat.session_id] = [];
                        }
                        acc[chat.session_id] = [...acc[chat.session_id], chat];
                        return acc;
                    }, {});

                const mostRecentChats = [];

                for (const chatSessionId in chatSessions) {
                    const chatSessionsById = chatSessions[chatSessionId];

                    const sortMessagesByLastMessageTimeProp = (a, b) => {
                        return (
                            new Date(b.last_message_time) -
                            new Date(a.last_message_time)
                        );
                    };

                    const mostRecentChat = chatSessionsById.sort(
                        sortMessagesByLastMessageTimeProp,
                    )[0];

                    mostRecentChats.push(mostRecentChat);
                }
                const sortedChats = mostRecentChats.sort((a, b) => {
                    return (
                        new Date(b.last_message_time) -
                        new Date(a.last_message_time)
                    );
                });

                setChats(sortedChats);
            } catch (error) {
                console.error("Failed to fetch chat sessions:", error);
            }
        };

        fetchChats();
    }, []);

    useEffect(() => {
        // Set up an interval to poll every second
        const intervalId = setInterval(async () => {
            try {
                // Make a POST request to the endpoint
                const response = await axios.get("/latest-chat-session");

                const { chat_session, chat_session_file } = response.data;

                const newChatMessage = {
                    ...chat_session,
                };

                newChatMessage.file_path = chat_session_file;

                //              console.log({ newChatMessage });

                const popupShownUUID = localStorage.getItem("popupShown");

                if (newChatMessage.uuid !== popupShownUUID) {
                    // If the UUID is different, update localStorage and call setLatestChat
                    localStorage.setItem("popupShown", newChatMessage.uuid);
                    setLatestChat(newChatMessage);
                }
            } catch (error) {
                console.error(error);
            }
        }, 1000); // 1000 milliseconds = 1 second

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (!latestChat || !latestChat?.last_message || latestChat?.is_local)
            return;

        console.log({ latestChat });

        toast.custom(
            (t) => (
                <div
                    className={`${
                        t.visible ? "animate-enter" : "animate-leave"
                    } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                    <div className="flex-1 w-0 p-4">
                        <div className="flex items-start">
                            <div className="flex-1 ml-3">
                                <p className="text-sm font-medium text-gray-900">
                                    {name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                    {latestChat?.last_message?.slice(0, 50) +
                                        "..."}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex border-l border-gray-200">
                        <button
                            onClick={() => {
                                fetchChatSession({
                                    sessionIdArg: latestChat?.session_id,
                                    toArg: latestChat?.to ?? latestChat?.from,
                                    setActiveChat,
                                    setChatMessages,
                                    setTo,
                                    setName,
                                    setSessionId,
                                    setIsChatVisible,
                                });
                                toast.dismiss(t?.id);
                            }}
                            className="flex items-center justify-center w-full p-4 text-sm font-medium text-indigo-600 border border-transparent rounded-none rounded-r-lg hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                            Resume Chat
                        </button>
                    </div>
                </div>
            ),
            {
                duration: 10000,
                position: "top-center",
            },
        );
    }, [latestChat]);

    return (
        <div className={`flex ${className}`}>
            <Card
                onClick={(event) => {
                    if (event.shiftKey) {
                        router.visit("/chat-sessions");
                        return;
                    }

                    setShowSearchModal(true);
                }}
                title="Click to start a new chat session. Shift + Click to view existing chat sessions."
            >
                <ChatBubbleLeftIcon className="w-8 h-8" />
            </Card>
            <Toaster />
        </div>
    );
};

export default ChatWidget;

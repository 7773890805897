// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            Success: "Success!",
            "You have been successfully registered.":
                "You have been successfully registered.",
            "First Name": "First Name",
            "Last Name": "Last Name",
            Speciality: "Speciality",
            "Phone Number": "Phone Number",
            Age: "Age",
            Crew: "Crew",
        },
    },
    es: {
        translation: {
            Success: "¡Éxito!",
            "You have been successfully registered.":
                "Usted ha sido registrado con éxito.",
            "First Name(s)": "Nombre(s)",
            "Last Name(s)": "Apellido(s)",
            Specialty: "Especialidad",
            "Phone Number": "Número Telefónico",
            Age: "Edad",
            Crew: "Equipo",
            "Language Preference": "Preferencia de Idioma",
            Address: "Dirección",
            "Email Address": "Correo Electrónico",
            "Employee Type": "Tipo de Empleado",
            "Choose Employee Type": "Elija el Tipo de Empleado",
            Employee: "Empleado",
            Subcontractor: "Subcontratista",
            English: "Inglés",
            Spanish: "Español",
            "Choose Preferred Language": "Elija el Idioma Preferido",
            Submit: "Enviar",
            "Select a Crew": "Elija un Equipo",
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;

import { useState, useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import customToastSuccess from "./customToastSuccess";

// Custom hook for WebSocket connection
const useWebSocket = (url, setToastMessage = () => {}) => {
    const [socket, setSocket] = useState(null);

    const connect = useCallback(() => {
        const ws = new WebSocket(url);

        ws.addEventListener("open", () => {
            console.log("WebSocket connection established");
            ws.send("WebSocket opened for job-time-remaining endpoint!");
        });

        ws.addEventListener("message", (event) => {
            const message = JSON.parse(event.data);
            handleMessage(message);
        });

        ws.addEventListener("error", (error) => {
            console.error("WebSocket error:", error);
        });

        ws.addEventListener("close", (event) => {
            console.log("WebSocket connection closed:", event);
            // Attempt to reconnect after a delay
            setTimeout(() => connect(), 5000);
        });

        setSocket(ws);
    }, [url]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: false positive
    useEffect(() => {
        connect();
        return () => {
            if (socket) {
                socket.close();
                socket.disconnect();
            }
        };
    }, [connect]);

    const handleMessage = (message) => {
        console.log({ messageType: message.type, messageData: message.data });
        switch (message.type) {
            case "job-time-remaining":
                handleJobTimeRemaining(message.data);
                break;
            // case "job-status-update":
            //     handleJobStatusUpdate(message.data);
            //     break;
            case "job-active":
                handleNotifyJobActive(message.data);
                break;
            case "job-past-due":
                handleNotifyJobPastDue(message.data);
                break;
            case "job-cancelled":
                handleNotifyJobCancelled(message.data);
                break;
            case "job-completed":
                handleNotifyJobCompleted(message.data);
                break;
            case "task-time-remaining":
                handleTaskTimeRemaining(message.data);
                break;
            case "task-active":
                handleNotifyTaskActive(message.data);
                break;
            case "task-past-due":
                handleNotifyTaskPastDue(message.data);
                break;
            case "task-cancelled":
                handleNotifyTaskCancelled(message.data);
                break;
            case "task-completed":
                handleNotifyTaskCompleted(message.data);
                break;
            case "upload-before-job-image-completed":
                handleUploadBeforeImageJobCompleted(message.data);
                break;
            case "upload-after-job-image-completed":
                handleUploadAfterImageJobCompleted(message.data);
                break;
            // case "notify-upload-incident-image":
            //     handleNotifyUploadIncidentImage(message.data);
            //     break;
            case "notify-submit-incident":
                handleNotifySubmitIncident(message.data);
                break;
            // Add cases for other message types as needed
            default:
                console.warn("Unknown message type:", message.type);
        }
    };

    const handleNotifySubmitIncident = async (data) => {
        const { employeeName, teamLeadName, incidentName, incidentId } = data;

        console.log({ data });

        console.log({ employeeName, teamLeadName, incidentName });

        const duration = 5000;

        if (employeeName) {
            customToastSuccess(
                {
                    message: `Incident "${incidentName}" has been submitted successfully by ${employeeName}!`,
                    id: incidentId,
                },
                {
                    duration,
                },
            );
        }

        if (teamLeadName) {
            customToastSuccess(
                {
                    message: `Incident "${incidentName}" has been submitted successfully by ${teamLeadName}!`,
                    id: incidentId,
                },
                {
                    duration,
                },
            );
        }
    };

    // const handleNotifyUploadIncidentImage = (data) => {
    //     console.log("Notify Upload Incident Image:", data);

    //     const { job_name, team_lead_name, metadata } = data;

    //     console.log({ metadata });

    //     toast.success(
    //         `An incident for ${job_name} has been submitted successfully by ${team_lead_name}!`,
    //     );
    // };

    const handleUploadBeforeImageJobCompleted = async (data) => {
        console.log("Data:", data);

        const { job_id } = data;

        try {
            const response = await axios.get(`/job/${job_id}`);

            if (response.status === 200) {
                const job = response.data;
                console.log("Job:", job);
                toast.success(`Before image uploaded for job: ${job.name}`);
            }
        } catch (error) {
            console.error("Error uploading before image:", error);
        }
    };

    const handleUploadAfterImageJobCompleted = async (data) => {
        const { job_id } = data;

        try {
            const response = await axios.get(`/job/${job_id}`);

            if (response.status === 200) {
                const job = response.data;
                console.log("Job:", job);
                toast.success(`After image uploaded for job: ${job.name}`);
            }
        } catch (error) {
            console.error("Error uploading after image:", error);
        }
    };

    const handleNotifyJobActive = (data) => {
        const { job_id, job_name, job_status } = data;
        toast.success(`${job_name} is now active!`);
    };

    const handleNotifyJobPastDue = (data) => {
        const { job_id, job_name, job_status } = data;
        toast.error(`${job_name} is past due!`);
    };

    const handleNotifyJobCancelled = (data) => {
        const { job_id, job_name, job_status } = data;
        toast.error(`${job_name} has been cancelled`);
    };

    const handleNotifyJobCompleted = (data) => {
        const { job_id, job_name, job_status } = data;
        toast.success(`${job_name} has been completed!`);
    };

    const handleNotifyTaskActive = (data) => {
        const { task_name } = data;
        toast.success(`${task_name} is now active!`);
    };

    const handleNotifyTaskPastDue = (data) => {
        const { task_name } = data;
        toast.error(`${task_name} is past due!`);
    };

    const handleNotifyTaskCancelled = (data) => {
        const { task_name } = data;
        toast.error(`${task_name} has been cancelled`);
    };

    const handleNotifyTaskCompleted = (data) => {
        const { task_id, task_name, task_status } = data;
        toast.success(`${task_name} has been completed!`);
    };

    const handleJobTimeRemaining = (data) => {
        console.log({ data });
        const {
            time_remaining,
            job_name,
            team_lead_name,
            team_lead_phone_number,
        } = data;
        toast.success(
            `${job_name} has ${time_remaining} remaining! Team lead: ${team_lead_name ?? "TBD"} (${team_lead_phone_number})`,
        );
    };

    const handleTaskTimeRemaining = (data) => {
        console.log({ data });
        const {
            time_remaining,
            task_name,
            team_lead_name,
            team_lead_phone_number,
        } = data;
        toast.success(
            `${task_name} has ${time_remaining} remaining! Team lead: ${team_lead_name ?? "TBD"} (${team_lead_phone_number})`,
        );
    };

    const handleJobStatusUpdate = (data) => {
        const { status, name: jobName } = data;
        switch (status) {
            case "PENDING":
                toast.success(`${jobName} is pending!`, { duration: 5000 });
                break;
            case "ACTIVE":
                toast.success(`${jobName} is active!`, { duration: 5000 });
                break;
            case "PAST_DUE":
                toast.error(`${jobName} is past due!`, { duration: 5000 });
                break;
            case "CANCELLED":
                toast.error(`${jobName} has been cancelled!`, {
                    duration: 5000,
                });
                break;
            case "COMPLETED":
                toast.success(`${jobName} has been completed!`, {
                    duration: 5000,
                });
                break;
            default:
                console.warn("Unknown job status:", status);
        }
    };

    return socket;
};

export default useWebSocket;

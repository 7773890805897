import { atom } from "jotai";

export const activeChatAtom = atom(null);
export const chatMessagesAtom = atom([]);
export const newMessageAtom = atom("");
export const isChatVisibleAtom = atom(false);
export const toAtom = atom(null);
export const nameAtom = atom(null);
export const userAtom = atom(null);
export const sessionIdAtom = atom(null);
export const fetchChatSessionAtom = atom(null);

// const [activeChat, setActiveChat] = useState(null);
// const [chatMessages, setChatMessages] = useState([]);
// const [newMessage, setNewMessage] = useState("");
// const [isChatVisible, setIsChatVisible] = useState(false);
// const [to, setTo] = useState(null);
// const [name, setName] = useState(null);
// const [user, setUser] = useState(null);
// const [sessionId, setSessionId] = useState(null);

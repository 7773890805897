const BlockCard = ({
    title,
    content,
    onClick,
    className = "w-auto text-white bg-gray-700 cursor-pointer card",
    titleClassName = "flex align-center gap-2 text-xl leading-7 font-bold",
    titleContClassName = "flex flex-col flex-1 p-[var(--padding-card,2rem)] gap-2",
}) => (
    <div className={className} onClick={onClick}>
        <div className={titleContClassName}>
            <h2 className={`${titleClassName}`}>{title}</h2>
            <p>{content}</p>
        </div>
    </div>
);

export default BlockCard;
